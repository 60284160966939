@import '../../styles/customMediaQueries.css';
@import '../../styles/customMediaQueries.css';

.sectionbody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    justify-content: space-between;

    @media (--viewLargeScreenMax) {
        flex-direction: column;
        padding-bottom: 60px;
        width: 100%;
    }

    @media (--viewLargeScreenMin) and (--viewLargeScreenMax) {
        padding-bottom: 100px;
    }

    @media (--viewXLargeScreenMin) {
        padding-bottom: 100px;
    }
}

.sectiontitle {
    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Clash Display;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;

    @media (--viewLargeScreenMax) {
        font-size: 35px;
    }

    @media (--viewLargeScreenMin) and (--viewLargeScreenMax) {
        font-size: 38px;
    }

    @media (--viewXLargeScreenMin) {
        font-size: 38px;
    }
}

.sectioncontent {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    justify-content: space-between;
    width: 100%;

    @media (--viewLargeScreenMax) {
        overflow-x: auto;
    }
}

.sectioncontentitem1 {
    display: flex;
    width: 547px;
    height: 467px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: left;
    align-items: center;
    justify-content: flex-start;
    padding-left: 32px;
    padding-top: 16px;

    flex-shrink: 0;
    border-radius: 20px;
    background: linear-gradient(178deg, #000 -16.2%, rgba(0, 0, 0, 0.00) 43.42%), url(../../assets/images/home/team5.png), lightgray 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.sectioncontentitem4 {
    display: flex;
    width: 547px;
    height: 467px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: left;
    align-items: center;
    justify-content: flex-start;
    padding-left: 32px;
    padding-top: 16px;

    flex-shrink: 0;
    border-radius: 20px;
    background: linear-gradient(178deg, #000 -16.2%, rgba(0, 0, 0, 0.00) 43.42%), url(../../assets/images/home/team7.png), lightgray 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.itemtitle {
    color: var(--primary-ffffff, #FFF);
    font-family: Clash Display;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    width: 100%;
}
