@import '../../styles/customMediaQueries.css';

.sectionbody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    justify-content: space-between;

    @media (--viewLargeScreenMax) {
        flex-direction: column;
        padding-bottom: 60px;
        width: 100%;
    }

    @media (--viewLargeScreenMin) and (--viewLargeScreenMax) {
        padding-bottom: 100px;
    }

    @media (--viewXLargeScreenMin) {
        padding-bottom: 100px;
    }
}

.sectioncontent {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;

    border-radius: 20px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.71) 100%), url(../../assets/images/home/customfeature2.png), lightgray 0px -147px / 100% 252.489% no-repeat;
    width: 100%;
    height: 442px;
    background-repeat: no-repeat;
    background-size: cover;
    
    @media (--viewLargeScreenMax) {
        height: auto;
    }
}

.sectiondescription {
    display: flex;
    align-items: flex-end;
    gap: 63px;

    @media (--viewLargeScreenMax) {
        padding: 100px 51px 45px 45px;
        flex-direction: column;
    }

    @media (--viewLargeScreenMin) and (--viewLargeScreenMax) {
        padding: 100px 51px 45px 45px;
        flex-direction: column;
    }

    @media (--viewXLargeScreenMin) {
        padding: 245px 51px 45px 45px;
        flex-direction: row;
    }
}

.sectiondescriptioncontent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
}

.sectiondescriptiontitle {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    color: var(--primary-ffffff, #FFF);

    /* H2 */
    font-family: Clash Display;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 48px */

    @media (--viewLargeScreenMax) {
        font-size: 35px;
    }

    @media (--viewLargeScreenMin) and (--viewLargeScreenMax) {
        font-size: 38px;
    }

    @media (--viewXLargeScreenMin) {
        font-size: 38px;
    }
}

.sectiondescriptiontext {
    color: var(--primary-ffffff, #FFF);
    /* P20 - Standard */
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 28px */
}

.sectionaction {
    display: flex;
    height: 53px;
    padding: 15px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 7px;
    background: var(--primary-ffffff, #FFF);

    color: var(--primary-1-b-242-d, #1B242D);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */

    @media (--viewLargeScreenMax) {
        width: 100%;
    }
}