@import '../../styles/customMediaQueries.css';

.sectionbody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    justify-content: space-between;

    @media (--viewLargeScreenMax) {
        flex-direction: column;
        padding-bottom: 60px;
        width: 100%;
    }

    @media (--viewLargeScreenMin) and (--viewLargeScreenMax) {
        padding-bottom: 100px;
    }

    @media (--viewXLargeScreenMin) {
        padding-bottom: 100px;
    }
}


.sectiontitle {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.sectiontitletext {
    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Clash Display;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;

    @media (--viewLargeScreenMax) {
        font-size: 35px;
    }

    @media (--viewLargeScreenMin) and (--viewLargeScreenMax) {
        font-size: 38px;
    }

    @media (--viewXLargeScreenMin) {
        font-size: 38px;
    }
}

.sectiontitleaction {
    display: flex;
    padding: 3px;
    align-items: center;
    gap: 4px;

    border-radius: 99px;
    background: var(--primary-eaf-6-f-6, #EAF6F6);

    @media (--viewLargeScreenMax) {
        display: none;
    }
}

.sectiontitleallbtn {
    display: flex;
    padding: 0px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    border-radius: 99px;
    background: var(--primary-eaf-6-f-6, #EAF6F6);
    backdrop-filter: blur(5px);

    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
}

.sectiontitlebtn {
    width: 47px;
    height: 47px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.sectioncontent {
    display: flex;
    align-items: flex-start;
    gap: 30px;

    @media (--viewLargeScreenMax) {
        overflow-x: auto;
        width: 100%;
    }
}