@import '../../styles/customMediaQueries.css';

.root {
    /* Expand to the available space */
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @media (--viewLargeScreenMax) {
        width: 100vw;
        align-items: flex-start;
    }

    @media (--viewXLargeScreenMin) {
        padding-top: 60px;
        align-items: center;
    }
}

.content {
    padding-left: 25px;
    padding-right: 25px;
    @media (--viewLargeScreenMax) {
        width: 100vw;
    }

    @media (--viewXLargeScreenMin) {
        width: 1100px;
        padding: 0px;
    }
}
