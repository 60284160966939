@import '../../styles/customMediaQueries.css';

.sectionbody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    justify-content: space-between;

    @media (--viewLargeScreenMax) {
        flex-direction: column;
        padding-bottom: 60px;
        width: 100%;
    }

    @media (--viewLargeScreenMin) and (--viewLargeScreenMax) {
        padding-bottom: 100px;
    }

    @media (--viewXLargeScreenMin) {
        padding-bottom: 100px;
    }
}

.sectiontitle {
    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Clash Display;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;

    @media (--viewLargeScreenMax) {
        font-size: 35px;
    }

    @media (--viewLargeScreenMin) and (--viewLargeScreenMax) {
        font-size: 38px;
    }

    @media (--viewXLargeScreenMin) {
        font-size: 38px;
    }
}

.sectioncontent {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;

    @media (--viewLargeScreenMax) {
        flex-direction: column;
    }

    @media (--viewLargeScreenMin) and (--viewLargeScreenMax) {
        flex-direction: row;
    }

    @media (--viewXLargeScreenMin) {
        flex-direction: row;
    }
}

.sectionleft {
    display: flex;
    padding: 35px 29px 28px 35px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    border-radius: 20px;
    background: var(--primary-eaf-6-f-6, #EAF6F6);

    @media (--viewLargeScreenMax) {
        width: 100%;
    }

    @media (--viewLargeScreenMin) and (--viewXLargeScreenMin) {
        width: 26.5vw;
    }
}

.sectionright {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    flex: 1 0 0;
    width: 44vw;

    @media (--viewLargeScreenMax) {
        width: 100%;
    }

    @media (--viewLargeScreenMin) and (--viewXLargeScreenMin) {
        width: 44vw;
    }
}

.sectionleftcontent {
    display: flex;
    height: 432px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 12px;

    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 30px */

    flex: 1 0 0;
    align-self: stretch;
}

.sectionrightitem {
    display: flex;
    padding: 30px;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    border-radius: 12px;
    background: var(--primary-eaf-6-f-6, #EAF6F6);
}

.sectionrightitemcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    flex: 1 0 0;

    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 133.333% */

    @media (--viewLargeScreenMax) {
        width: 100%;
    }
}

.sectionrightitemtitle {
    display: flex;
    width: 420px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Clash Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    @media (--viewLargeScreenMax) {
        width: 100%;
    }
}

.sectionleftcontenttext {
    display: flex;
    justify-content: flex-start;
    height: 100%;
}