@import '../../styles/customMediaQueries.css';

.sectionbody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    justify-content: space-between;

    @media (--viewLargeScreenMax) {
        padding-bottom: 60px;
        width: 100%;
    }

    @media (--viewLargeScreenMin) and (--viewLargeScreenMax) {
        padding-bottom: 100px;
    }

    @media (--viewXLargeScreenMin) {
        padding-bottom: 100px;
    }
}

.sectioncontent {
    display: flex;
    padding-left: 0px;
    justify-content: space-between;
    align-items: center;
    gap: 68px;
    width: 100%;

    @media (--viewLargeScreenMax) {
        flex-direction: column;
        gap: 15px;
    }

    @media (--viewLargeScreenMin) {
        flex-direction: row;
        background: var(--primary-1-b-242-d, #1B242D);
        padding-left: 35px;
        border-radius: 20px;
    }
}

.sectionleft {
    display: flex;
    width: 468px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 40px;

    @media (--viewLargeScreenMax) {
        width: 100%;
        background: var(--primary-1-b-242-d, #1B242D);
        border-radius: 20px;
        padding: 20px;
    }
}

.sectionright {
    width: 539px;
    height: 442px;
    border-radius: 20px;
    background: url(../../assets/images/home/year.png), lightgray 50%;
    background-size: cover;
    background-repeat: no-repeat;

    @media (--viewLargeScreenMax) {
        width: 100%;
    }
}

.sectionleftcontent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    flex: 1 0 0;
    align-self: stretch;
}

.sectionlefttitle {
    color: var(--primary-ffffff, #FFF);

    /* H3 */
    font-family: Clash Display;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    /* 43.75px */

    width: 464px;
    flex-shrink: 0;

    display: flex;
    align-items: flex-start;
    gap: 10px;

    @media (--viewLargeScreenMax) {
        width: 100%;
    }
}

.sectionlefttext {
    color: var(--primary-ffffff, #FFF);

    /* P16 - Medium */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
}

.sectionleftbutton {
    display: flex;
    height: 53px;
    padding: 15px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 7px;
    background: var(--extended-06-c-167, #06C167);

    @media (--viewLargeScreenMax) {
        width: 100%;
    }
}