@import '../../styles/customMediaQueries.css';

.sectionbody {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    justify-content: space-between;

    @media (--viewLargeScreenMax) {
        flex-direction: column;
        padding-bottom: 60px;
        width: 100%;
    }

    @media (--viewXLargeScreenMin) {
        padding-bottom: 100px;
        flex-direction: row;
    }
}

.sectionleft {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
    gap: 49px;

    @media (--viewLargeScreenMax) {
        width: 100%;
    }
}

.sectionlefttitle {
    color: #000;

    /* H2 */
    font-family: Clash Display;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 48px */

    align-self: stretch;

    @media (--viewLargeScreenMax) {
        font-size: 35px;
    }

    @media (--viewXLargeScreenMin) {
        font-size: 38px;
    }
}

.sectionleftcontent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
}

.sectionleftitem {
    border-radius: 20px;
    background: var(--primary-eaf-6-f-6, #EAF6F6);
    width: 100%;
    height: 113px;
    flex-shrink: 0;
    padding-top: 41px;
    padding-left: 45px;

    @media (--viewLargeScreenMax) {
        padding: 18px 49px 18px 49px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.sectionleftitemcontent {
    display: inline-flex;
    align-items: center;
    gap: 24px;

    @media (--viewLargeScreenMax) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
    }
}

.sectionleftitemtext {
    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 133.333% */
}

.sectionright {
    display: flex;
    height: 398px;
    padding: 30px 39.496px 30px 29px;
    align-items: center;
    flex: 1 0 0;

    border-radius: 20px;
    background: var(--primary-eaf-6-f-6, #EAF6F6);

    @media (--viewLargeScreenMax) {
        height: auto;
    }
}

.sectionrightcontent {
    display: flex;
    height: 338px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 50px;

    @media (--viewLargeScreenMax) {
        height: auto;
    }
}

.sectionrightitem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;
    justify-content: space-between;
}

.sectionrightitemtext {
    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 24px */
}

.sectionrightitemfooter {
    display: flex;
    align-items: center;
    gap: 20px;
}

.avatar {
    width: 52px;
    height: 52px;

    border-radius: 999px;
    /* border: 5px solid var(--primary-ffffff, #FFF); */
    background: url(../../assets/images/home/avatar.png), lightgray 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3px;

    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.profilename {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;

    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 133.333% */
}