.cardbody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
    background: #FFF;
}

.cardimage {
    width: 255px;
    height: 322px;
    flex-shrink: 0;
    border-radius: 15px;

    background-image: url(../../assets/images/home/feature1.png);
    background-repeat: no-repeat;
    background-size: cover;

    padding: 15px;

    cursor: pointer;
}

.cardcontent {
    display: flex;
    padding: 15px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.cardtitle {
    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Clash Display;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    &:hover {
        text-decoration: none;
    }
}

.cardhotel {
    color: var(--primary-1-b-242-d, #1B242D);

    /* P12 - Medium */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 16.8px */
}

.cardprice {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    opacity: 0.7;

    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 16.8px */
}

.favourite {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.locationtext {
    color: var(--primary-1-b-242-d, #1B242D);
    /* P12 - Medium */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 16.8px */
}

.locationcontent {
    display: flex;
    padding: 4px 15px;
    justify-content: center;
    align-items: center;
    gap: 5px;

    border-radius: 3px;
    background: rgba(234, 246, 246, 0.78);
    backdrop-filter: blur(5px);
}

.location {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    margin-top: 250px;
}