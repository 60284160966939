@import '../../styles/customMediaQueries.css';

.sectionbody {
    display: flex;
    align-items: flex-start;
    gap: 32px;
    justify-content: space-between;

    @media (--viewLargeScreenMax) {
        padding-bottom: 60px;
        width: 100%;
        flex-direction: column-reverse;
    }

    @media (--viewLargeScreenMin) {
        padding-bottom: 100px;
        flex-direction: row;
    }
}

.sectionleft {
    display: flex;
    height: 467px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;

    @media (--viewLargeScreenMax) {
        width: 100%;
        height: 373px;
    }
}

.sectionleftimage {
    border-radius: 20px;
    background: url(../../assets/images/home/livedemo.png), lightgray 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 540px;
    height: 467px;

    @media (--viewLargeScreenMax) {
        width: 100%;
        height: 373px;
    }
}

.sectionright {
    flex: 1 0 0;
    width: 540px;
    height: 467px;
    flex-shrink: 0;
    border-radius: 20px;
    background: var(--primary-1-b-242-d, #1B242D);
    padding: 35px;

    @media (--viewLargeScreenMax) {
        width: 100%;
        height: 373px;
    }
}

.sectionrightcontent {
    display: flex;
    width: 436px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    @media (--viewLargeScreenMax) {
        width: 100%;
    }
}

.sectionrightcontenttitle {
    color: var(--primary-ffffff, #FFF);

    /* H4 */
    font-family: Clash Display;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 36px */
}

.sectionrightcontenttitle2 {
    color: var(--extended-97-eae-5, #97EAE5);
    /* H4 */
    font-family: Clash Display;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.sectionrightcontenttext {
    color: var(--primary-ffffff, #FFF);
    
    /* P16 - Medium */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
}

.righteffect {
    display: flex;
    justify-content: flex-end;
    height: 125px;
}

.effect1 {
    width: 147.196px;
    height: 211.94px;
    flex-shrink: 0;
    margin-right: -30px;
    margin-top: -70px;
}

.effect2 {
    width: 83.227px;
    height: 120.79px;
    flex-shrink: 0;
    margin-top: 30px;
    margin-right: -17px;
}