@import '../../styles/customMediaQueries.css';

.sectionbody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    justify-content: space-between;

    @media (--viewLargeScreenMax) {
        flex-direction: column;
        padding-bottom: 60px;
        width: 100%;
    }

    @media (--viewXLargeScreenMin) {
        padding-bottom: 100px;
        flex-direction: row;
    }
}

.sectiondescription {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;

    @media (--viewXLargeScreenMin) {
        width: 445px;
    }
}

.sectiondescriptioncontent {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;

    @media (--viewXLargeScreenMin) {
        width: 445px;
    }
}

.sectiondescriptionaction {
    display: flex;
    padding: 15px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 7px;

    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
}

.sectiondescriptiontitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Clash Display;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;

    @media (--viewLargeScreenMax) {
        font-size: 35px;
    }

    @media (--viewXLargeScreenMin) {
        font-size: 38px;
    }

}

.sectiondescriptiontitle2 {
    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.sectiondescriptiontitle3 {
    color: rgba(27, 36, 45, 0.70);
    font-family: Poppins;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
}

.sectionimage {
    display: flex;

    @media (--viewLargeScreenMax) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (--viewXLargeScreenMin) {
        width: 647.496px;
    }
}

.sectionimageitem {
    flex-shrink: 0;
    border-radius: 8.717px;

    @media (--viewLargeScreenMax) {
        width: 100%;
        height: auto;
    }

    @media (--viewXLargeScreenMin) {
        width: 647.496px;
        height: 394px;
    }
}

.actionitem {
    color: #000;
    font-family: Poppins;
    font-size: 10.896px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    display: inline-flex;
    padding: 16.344px 21.792px;
    justify-content: center;
    align-items: center;
    gap: 10.896px;

    border-radius: 10.896px;
    background: rgba(255, 255, 255, 0.70);
    box-shadow: 0px 10.89602px 21.79204px 5.44801px rgba(71, 78, 88, 0.05), 0px 9.82544px 19.65087px 4.91272px rgba(71, 78, 88, 0.15);
}