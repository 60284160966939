@import '../../styles/customMediaQueries.css';

.sectionbody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    justify-content: space-between;

    @media (--viewLargeScreenMax) {
        flex-direction: column;
        padding-bottom: 60px;
        width: 100%;
    }

    @media (--viewLargeScreenMin) and (--viewLargeScreenMax) {
        padding-bottom: 100px;
    }

    @media (--viewXLargeScreenMin) {
        padding-bottom: 100px;
    }
}

.sectiontitle {
    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Clash Display;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;

    @media (--viewLargeScreenMax) {
        font-size: 35px;
    }

    @media (--viewLargeScreenMin) and (--viewLargeScreenMax) {
        font-size: 38px;
    }

    @media (--viewXLargeScreenMin) {
        font-size: 38px;
    }
}

.sectioncontent {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;

    @media (--viewLargeScreenMax) {
        flex-direction: column;
    }

    @media (--viewLargeScreenMin) and (--viewLargeScreenMax) {
        flex-direction: column;
    }

    @media (--viewXLargeScreenMin) {
        flex-direction: row;
    }
}

.tipsitem {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    border-radius: 12px;
    background: var(--primary-eaf-6-f-6, #EAF6F6);

    @media (--viewLargeScreenMax) {
        width: 100%;
    }

    @media (--viewLargeScreenMin) and (--viewLargeScreenMax) {
        width: 100%;
    }

    @media (--viewXLargeScreenMin) {
        width: 255px;
    }
}

.tipstitle {
    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Clash Display;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.tipsdescription {
    color: var(--primary-1-b-242-d, #1B242D);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    opacity: 0.7;
    align-self: stretch;
}